import React, { useState } from "react";
import { Box, AppBar, Toolbar } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import MainMenu from "../router/menu";
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../auth/auth.actions";

interface AdminNavProps {
    isAdmin: boolean
}

const AdminNav: React.FC<AdminNavProps> = ({ isAdmin }) => {
    const [sideMenuOpen, setSideMenuOpen] = useState(false)

    return < >
    <AppBar position='sticky' color="secondary" >
        <Toolbar>
            {isAdmin && (
                <IconButton
                    size='large'
                    edge="start"
                    color="inherit"
                    aria-label='menu'
                    sx={{ mr: "8px" }}
                    onClick={() => setSideMenuOpen(!sideMenuOpen)}
                >
                    <MenuIcon />
                </IconButton>
            )}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Burning Brumby
            </Typography>
            <Button
                color="secondary"
                endIcon={<LogoutIcon />}
                onClick={logout}
            >
                Logout
            </Button>
        </Toolbar>
    </AppBar>
    <MainMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
    </>
}

export default AdminNav
