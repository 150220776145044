import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { EventRSVP, validRSVP } from "./event.types";
import { DocumentReference, updateDoc } from "firebase/firestore";
import { useStripe } from "@stripe/react-stripe-js";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography'
import { sendRSVPEmail } from "../firebase";
import { once } from "lodash";
import { Link } from "react-router-dom";

interface rsvpSuccessProps {
    rsvp: EventRSVP
    rsvpRef: DocumentReference<EventRSVP>
}

// checks if an rsvp has been successful and marks the success if it has
// needs to get the payment intent in order to check if the payment was successful
// should be mounted inside the rsvp rapper in order to access stripe
const RsvpSuccess: React.FC<rsvpSuccessProps> = ({ rsvp, rsvpRef }) => {
    const stripe = useStripe()

    const sendEmail = once((name: string, email: string) => {
        sendRSVPEmail({ name, emailAddress: email })
        .then(() => {
            updateDoc(rsvpRef, { confirmEmailSent: true })
        })
        .catch(e => console.log("emailerror", e))
    })

    const confirmInFirebase = () => {
        updateDoc(rsvpRef, { rsvpConfirmed: true })
        if (!rsvp.confirmEmailSent) {
            sendEmail(rsvp.name, rsvp.userEmail)
        }
    }

    const [successLoading, setSuccessLoading] = useState(true)
    const [rsvpSuccess, setRsvpSuccess] = useState(false)
    const [errorText, setErrorText] = useState("")
    useEffect(() => {
        // do some shit on load
        // basically:
        // - if we've already marked the rsvp as confirmed they're good
        // - if they aren't donating then it's an instant success
        // -- (although we should check if they've done the shit)
        // - otherwise if they are donating check the stripe status

        // we've already run this so exit
        if (rsvpSuccess || !successLoading) {
            setSuccessLoading(false)
            return
        }

        if (rsvp.rsvpConfirmed) {
            setSuccessLoading(false)
            setRsvpSuccess(true)
            return
        }

        if (rsvp.noDonate) {
            // Check that they've filled out the required info and mark success in firebase
            if (validRSVP(rsvp)) {
                setRsvpSuccess(true)
                confirmInFirebase()
            } else {
                setErrorText("some details are incorrect, please go back to the rsvp page and make sure you've filled everything in")
            }
            setSuccessLoading(false)
            return
        }

        // ok, they're donating, so we need to check the stripe status
        if (!stripe) {
            return
        }

       stripe.retrievePaymentIntent(rsvp.paymentClientSecret)
       .then(({ error, paymentIntent }) => {
            if (error) {
                setErrorText("Error getting donation details, maybe contact Karl or something..")
                return
            }

            if (paymentIntent.status === "succeeded") {
                confirmInFirebase()
                setRsvpSuccess(true)
            } else {
                setErrorText(`Donation failed with error code: ${paymentIntent.status}`)
            }

            setSuccessLoading(false)
       })
    }, [stripe, rsvpSuccess, rsvp, successLoading])

    return <Box sx={{ height: 1.0 }}>
        {successLoading && <CircularProgress />}
        {errorText !== "" && <Typography variant="body1">
            {`Error with rsvp: ${errorText}`}
        </Typography>}
        {rsvpSuccess && <>
            <Typography variant="body1">
                {"Thanks for reserving your place at burning brumby! Looking forward to seeing you soon"}
            </Typography>
            <Typography variant="body1">
                {"Use the link below to join our slack where we'll be doing most of our comms"}
            </Typography>
            <Typography style={{ color: "white", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open("https://join.slack.com/t/tassieburn/shared_invite/zt-1kvmxvzty-Szrgt38VH6XbSC0o5hfJNw")}>
                {"Join slack!"}
            </Typography>
        </>}
    </Box>
}

export default RsvpSuccess
