import React from 'react'
import { Box } from "@mui/material"
import AdminNav from '../admin/adminNav'
import { Outlet } from 'react-router-dom'
import { User } from '../user/user.types'

interface MainContainerProps {
    user: User
    backgroundURL: string
}

const MainContainer: React.FC<MainContainerProps> = (props) => {
    return <Box
            className='main-container'
            sx={{
                height: "100%"
            }}
            >
            <AdminNav isAdmin={props.user.isAdmin} />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                // flexGrow: 1,
                backgroundImage: `url("${props.backgroundURL}") `,
                backgroundAttachment: "fixed",
                alignItems: "center",
                minHeight: "calc(100%)"
                // height: "fit-content"
            }}>
                <Outlet />
            </Box>
        </Box>
}

export default MainContainer
