import React, { useState } from "react"
import { Box } from "@mui/material"
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import { useUsers } from "../user/user.actions"
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridColDef, GridToolbar, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { modalStyle } from "../theme"
import LoadingButton from '@mui/lab/LoadingButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { doc, setDoc, updateDoc, writeBatch } from "firebase/firestore"
import { firestoreDB } from "../firebase"

const UserAdmin: React.FC = () => {
    const [users, usersLoading, usersError, usersSnapshot] = useUsers()

    const enableUser = (email: string, isEnabled: boolean) => {
        // console.log(email, isEnabled)
        const d = doc(firestoreDB, "users", email)
        updateDoc(d, { isEnabled })
        .catch(e => console.log(e))
    }

    interface rowModel extends GridValidRowModel {
        email: string
        enabled: boolean
    }

    const columns: GridColDef[] = [
        { field: "email", headerName: "Email address", sortable: true, width: 300 },
        {
            field: "enabled",
            headerName: "Is enabled",
            sortable: true,
          renderCell: (params: GridRenderCellParams<boolean, rowModel>) => (
            <Checkbox onClick={() => enableUser(params.row.email, !params.value)} checked={params.value} />
          )
        }
    ]

    const rows = (users ?? []).map(u => ({
        id: u.email,
        email: u.email,
        enabled: u.isEnabled
    }))

    const [tab, setTab] = useState(0)

    const [usersToAdd, _setUsersToAdd] = useState("")
    const [addUsersLoading, setAddUsersLoading] = useState(false)
    const [addUsersValid, setAddUsersValid] = useState(false)

    const setUsersToAdd = (val: string) => {
        // separate out the string by comma or nl and check that they are all valid emails
        const lines = val.includes(',')
            ? val.split(',')
            : val.split('\n')

        // setAddUsersValid(true)
        setAddUsersValid(lines.map(l => l.trim()).every(e => e.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)))
        _setUsersToAdd(val)
    }

    const onAddUsers = () => {
        setAddUsersLoading(true)

        const lines = usersToAdd.includes(',')
            ? usersToAdd.split(',')
            : usersToAdd.split('\n')

        const batch = writeBatch(firestoreDB)

        lines.map(l => l.trim()).forEach(e => {
            const d = doc(firestoreDB, "users", e)
            batch.set(d, { isEnabled: true }, { merge: true })
        })

        batch.commit()
            .catch(e => console.log(e))
            .finally(() => {
                setAddUsersLoading(false)
                setUsersToAdd("")
            })
    }

    return <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 0.8
        }}>
        <Tabs value={tab} onChange={(e, i) => setTab(i)}>
            <Tab label="Users table" />
            <Tab label="Add users" />
        </Tabs>
        {tab === 0 && (
        <Box sx={{ height: "600px", width: '75%' }}>
            <DataGrid
                sx={{ height: 1.0, backgroundColor: "black" }}
                rows={rows}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
            />
        </Box>
        )}
        {tab === 1 && (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                backgroundColor: "secondary",
                p: 2,
                '& > :not(style)': {
                    my: 1
                }
        }}>
            <Typography variant="h6">Add users</Typography>
            <Typography variant="body1">Enter emails seperated by commas or new lines</Typography>
            <Typography variant="body1">New users will be added and existing users will be enabled</Typography>
            <TextField
                required
                fullWidth
                label="New users"
                multiline
                value={usersToAdd}
                minRows={10}
                error={!addUsersValid}
                onChange={(e) => setUsersToAdd(e.target.value)}
            />
            <LoadingButton
                loading={addUsersLoading}
                loadingPosition="start"
                variant="contained"
                disabled={!addUsersValid}
                onClick={onAddUsers}
                >
                    Add users
            </LoadingButton>
            </Box>
            )}
    </Box>
}

export default UserAdmin;
