export interface EventDetails {
  name: string
  title: string
  ticketsUrl: string
  showTickets: boolean
  details: string
  isVisible: boolean
  lastUpdated: {
    by: string
    when: string
  }
}

export interface EventRSVP {
  userEmail: string
  name: string
  selfChecked: boolean
  parkingChecked: boolean
  websiteChecked: boolean
  ownIdeas: string
  kitchen: boolean
  land: boolean
  workshop: boolean
  construction: boolean
  sober: boolean
  ownIdea: boolean
  yours: boolean
  noDonate: boolean

  rsvpConfirmed: boolean
  confirmEmailSent?: boolean
  paymentClientSecret?: string
  paymentConfirmed: boolean
  paymentID?: string
  donationAmount?: number
}

export const newEventRSVP = (userEmail: string): EventRSVP => ({
  userEmail,
  name: "",
  selfChecked: false,
  parkingChecked: false,
  websiteChecked: false,
  ownIdeas: "",
  kitchen: false,
  land: false,
  workshop: false,
  construction: false,
  sober: false,
  ownIdea: false,
  yours: false,
  noDonate: false,
  rsvpConfirmed: false,
  paymentConfirmed: false,
  donationAmount: 30
})

export const validRSVP = (rsvp: EventRSVP): boolean => {
  return rsvp.parkingChecked &&
  rsvp.websiteChecked &&
  rsvp.selfChecked && (
    rsvp.kitchen ||
    rsvp.land ||
    rsvp.workshop ||
    rsvp.construction ||
    rsvp.sober ||
    rsvp.ownIdea ||
    rsvp.yours
  ) && (
    !rsvp.ownIdea || rsvp.ownIdeas !== ""
  ) &&
  rsvp.name !== ""
}
