import React from "react";
import Drawer from '@mui/material/Drawer';
import { NavLink, Outlet } from "react-router-dom"
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon } from "@mui/material"
import SettingsIcon from '@mui/icons-material/Settings';
import WhatshotIcon from '@mui/icons-material/Whatshot';

interface MainMenuProps {
    open: boolean
    onClose: () => void
}

const MainMenu: React.FC<MainMenuProps> = (props) => {
    return <Drawer
        anchor="left"
        open={props.open}
        onClose={props.onClose}
        >
        <List>
            <ListItem onClick={props.onClose}>
                <NavLink
                    to="/admin"
                    >
                    <ListItemButton>
                        <ListItemIcon>
                            <SettingsIcon />
                            <ListItemText primary={"Admin"} />
                        </ListItemIcon>
                    </ListItemButton>
                </NavLink>
            </ListItem>
            <ListItem onClick={props.onClose}>
                <NavLink
                    to="/events"
                    >
                    <ListItemButton>
                        <ListItemIcon>
                            <WhatshotIcon />
                            <ListItemText primary={"Events"} />
                        </ListItemIcon>
                    </ListItemButton>
                </NavLink>
            </ListItem>
        </List>
    </Drawer>
}

export default MainMenu;
