import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material"
import { AppMeta } from "../meta/meta.types";
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { useStripe, PaymentElement, Elements, useElements } from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import { stripePromise } from "../stripe";
import { httpsCallable } from "firebase/functions";
import { createPayment, firestoreDB, updatePayment } from "../firebase";
import { PaymentIntent, Stripe } from "@stripe/stripe-js";
import { debounce } from "lodash"
import { LoadingButton } from "@mui/lab";
import { doc, DocumentReference, setDoc, updateDoc } from "firebase/firestore";
import { useRSVP } from "./event.actions";
import { EventRSVP, newEventRSVP } from "./event.types";
import { createBrowserRouter, Route, RouterProvider, Routes, useNavigate } from "react-router-dom";
import RsvpSuccess from "./rsvpSuccess";
import Container from '@mui/material/Container';

interface RSVPRapperProps {
    appMeta: AppMeta
    userEmail: string
}

// component to rapp the payment element and fetch the client secret
const RSVPRapper: React.FC<RSVPRapperProps> = ({ appMeta, userEmail }) => {
    const [rsvp, rsvpLoading, rsvpError, rsvpRef] = useRSVP(appMeta.currentEvent, userEmail)

    // if we don't have an RSVP doc already make sure we create it before continuing
    // this will also trigger the creation of a payment intent and attach the client secret to the rsvp doc
    useEffect(() => {
        const exists = rsvpRef?.exists()
        if (!rsvpLoading && !rsvpError && !rsvp && !exists) {
            setDoc<EventRSVP>(rsvpRef.ref, newEventRSVP(userEmail))
        }
    }, [rsvpLoading, rsvpError, rsvpRef, rsvp])

    const [clientSecret, setClientSecret] = useState(rsvp?.paymentClientSecret)

    useEffect(() => {
        // listen for an update of the rsvp doc and set the client secrete when we get it
        if (rsvp?.paymentClientSecret) {
            setClientSecret(rsvp.paymentClientSecret)
        }
    }, [rsvp])

    return <Container fixed><Box sx={{
            padding: "16px",
            backgroundColor: "#0d1117",
            // width: "60%",
            my: "64px",
            borderRadius: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }} >
        {(rsvpLoading || !clientSecret) && <CircularProgress />}
        {!rsvpLoading && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }} >
                <RSVPRouter rsvp={rsvp} rsvpRef={rsvpRef.ref} />
            </Elements>
        )}
    </Box></Container>
}

interface RSVPRouterProps {
    rsvp: EventRSVP
    rsvpRef: DocumentReference<EventRSVP>
}

const RSVPRouter: React.FC<RSVPRouterProps> = ({ rsvp, rsvpRef }) => {
    return <Routes>
            <Route path="/success" element={<RsvpSuccess rsvp={rsvp} rsvpRef={rsvpRef} />} />
            <Route path="*" element={ <RSVP rsvp={rsvp} rsvpRef={rsvpRef} />} />
        </Routes>
}

interface RSVPProps {
    rsvp: EventRSVP
    rsvpRef: DocumentReference<EventRSVP>
}

const RSVP: React.FC<RSVPProps> = ({ rsvp, rsvpRef }) => {
    const stripe = useStripe()
    const elements = useElements()
    const navitatie = useNavigate()

    // if they have already rsvp'd then just redirect them to the success page
    useEffect(() => {
        if (rsvp.rsvpConfirmed) {
            navitatie("/rsvp/success")
        }
    }, [rsvp])

    // load our payment intent on mount
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>(undefined)
    useEffect(() => {
        if (stripe) {
            stripe.retrievePaymentIntent(rsvp.paymentClientSecret)
            .then(result => {
                if (result.error) {
                    console.log(result.error)
                }
                setPaymentIntent(result.paymentIntent)
            })
            .catch(e => console.log(e))
        }
    }, [stripe])

    const [name, setName] = useState(rsvp.name)

    const [submitLoading, setSubmitLoading] = useState(false)

    const [selfChecked, setSelfChecked] = useState(rsvp.selfChecked)
    const [parkingChecked, setParkingChecked] = useState(rsvp.parkingChecked)
    const [websiteChecked, setWebsiteChecked] = useState(rsvp.websiteChecked)
    const [noDonate, setNoDonate] = useState(rsvp.noDonate)
    const [paymentAmount, setPaymentAmount] = useState(rsvp.donationAmount)

    const [errorText, setErrorText] = useState("")

    interface multiField {
        id: string
        text: string
    }

    const multiOptions: multiField[] = [
        {
            id: "kitchen",
            text: "Kitchen Crew - Cook and/or clean up after communal meals"
        },
        {
            id: "land",
            text: "Land care - Removal of spanish heath & other invasive weeds"
        },
        {
            id: "workshop",
            text: "Deliver a Workshop - Share your skills"
        },
        {
            id: "construction",
            text: "Construction - Build a DJ both & effigy  (BYO tools welcome!)"
        },
        {
            id: "sober",
            text: "Sober people - 1x 6 hr shift (first aid training preferred)"
        },
        {
            id: "ownIdea",
            text: "I have my own awesome idea for a contribution!!"
        },
        {
            id: "yours",
            text: "I'm yours to do with as you see fit! Chuck me anywhere!"
        }
    ]

    const [multiSelect, setMultiSelect] = useState<Record<string, boolean>>(
        multiOptions.reduce((prev, field) => {
            prev[field.id] = rsvp[field.id]
            return prev
        }, {})
    )

    const [ownIdeas, setOwnIdeas] = useState(rsvp.ownIdeas)

    const updateRSVP = async () => await updateDoc<EventRSVP>(rsvpRef, {
            name,
            selfChecked,
            parkingChecked,
            websiteChecked,
            noDonate,
            donationAmount: paymentAmount,
            ...multiSelect,
            ownIdeas
        })
        .catch(e => console.log(e))

    // update our doc every couple of seconds so things are saved
    const debouncedDocUpdate = debounce(
        updateRSVP, 2000
    )

    useEffect(() => debouncedDocUpdate(),
        [multiSelect, name, selfChecked, parkingChecked, websiteChecked, noDonate, paymentAmount, ownIdeas]
    )

    const [updatingPayment, setUpdatingPayment] = useState(false)
    const deboundedPaymentUpdate = useCallback(debounce(
        (amount: number) => {
            updatePayment({
                paymentID: rsvp.paymentID,
                amount
            })
            .then(() => {
                // after the update fetch the intent again
                elements.fetchUpdates()
                stripe.retrievePaymentIntent(rsvp.paymentClientSecret)
                .then(result => {
                    if (result.error) {
                        console.log(result.error)
                    }
                    setPaymentIntent(result.paymentIntent)
                })
            })
            .finally(() => setUpdatingPayment(false))
        }, 1000
    ), [rsvp, elements, stripe])

    useEffect(() => {
        if (paymentIntent && stripe && elements && paymentIntent.amount !== paymentAmount * 100) {
            const a = paymentAmount * 100
            setUpdatingPayment(true)
            deboundedPaymentUpdate(a)
}
    }, [paymentAmount, paymentIntent, stripe, elements])

    const canSubmit = () =>
        Object.values(multiSelect).some(m => m) &&
            parkingChecked &&
            websiteChecked &&
            selfChecked &&
            name !== ""
        // don't need to check the stripe thing since it handles it for us

    const onSubmit = () => {
        setSubmitLoading(true)

        // update our document before submitting
        updateRSVP()
        .then(() => {
            if (noDonate) {
                // TODO - also send an email
                navitatie("/rsvp/success")
            }

            // they want to dontate, do the stripe thing
            const portString = window.location.port !== ""
                ? `:${window.location.port}`
                : ""
            stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.protocol}//${window.location.hostname}${portString}/rsvp/success`
                }
            })
            .then((error) => {
                if (error) {
                    setErrorText(error.error.message)
                }
            })
            .finally(() => setSubmitLoading(false))
        })
    }

    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }}>
        <Typography align="center" variant="h3">RSVP to Burning Brumby</Typography>
        <Typography align="center" variant="body1">{`Fill in the details below and press the 'RSVP' button to RSVP for ${rsvp.userEmail}`}</Typography>
        <Box sx={{
            // mx: 2,
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            mt: 2
        }}>
            <TextField
                fullWidth
                error={name === ""}
                helperText="Please enter a name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <Divider sx={{ my: 1 }} />
            <Typography sx={{ mb: 1 }} variant="body1">How would you like to contribute to Burning Brumby?</Typography>
            <FormGroup>
                { multiOptions.map(o => (
                    <FormControlLabel
                        key={o.id}
                        label={o.text}
                        control={
                            <Checkbox
                                checked={multiSelect[o.id]}
                                onChange={() => setMultiSelect({ ...multiSelect, [o.id]: !multiSelect[o.id] })}
                            />
                        }
                    />
                ))}
            </FormGroup>
            {(multiSelect.workshop || multiSelect.ownIdea) && (
                <TextField
                    multiline
                    minRows={7}
                    required
                    label="Let's hear your contribution ideas for the event!"
                    value={ownIdeas}
                    onChange={(e) => setOwnIdeas(e.target.value)}
                />
            )}
            <Divider sx={{ my: 1 }} />
            <Typography sx={{ mb: 1 }} variant="body1">Please confirm the following</Typography>
            <FormGroup>
                <FormControlLabel
                    label="I understand that I have to be fully self-reliant, bringing enough shelter, clothing, food and water to last 4 days in wild summer weather on the Tassie East Coast..."
                    control={
                        <Checkbox
                            checked={selfChecked}
                            onChange={() => setSelfChecked(!selfChecked)}
                            required
                        />
                    }
                />
                <FormControlLabel
                    label="I understand that there is extremely limited parking on site (40 spots) and I will carpool or will need to park in Bicheno and be shuttled to the site."
                    control={
                        <Checkbox
                            checked={parkingChecked}
                            onChange={() => setParkingChecked(!parkingChecked)}
                            required
                        />
                    }
                />
                <FormControlLabel
                    label="I have read the website and understand the principles of Burning Man"
                    control={
                        <Checkbox
                            checked={websiteChecked}
                            onChange={() => setWebsiteChecked(!websiteChecked)}
                            required
                        />
                    }
                />
            </FormGroup>
            <Divider />
            <Typography variant="h3" sx={{ my: 1 }} align="center">Donation</Typography>
            <Typography variant="body1">In order to cover the infrastructure and admin costs of the burn we are asking for a small donation.</Typography>
            <Typography variant="body1">{"If you are unable to donate please don't worry."}</Typography>
            <Typography variant="body1">Our recommended donation is $30, but feel free to donate as much or as little as you can.</Typography>
            <Typography variant="body1">{"Donations are processed using Stripe's secure payment system."}</Typography>
            {!noDonate && (<>
                <TextField
                    sx={{ my: 1 }}
                    fullWidth
                    error={paymentAmount < 2}
                    helperText="Minimum amount is $2 to cover processing feeds"
                    label="Please enter how much you'd like to donate"
                    value={paymentAmount}
                    type="number"
                    onChange={(e) => setPaymentAmount(parseInt(e.target.value))}
                />
                {updatingPayment
                ? (<CircularProgress />)
                : (
                    <PaymentElement />
                )}
            </>)}
            {errorText !== "" && (
                <Typography variant="body2" sx={{ color: "red" }}>{errorText}</Typography>
            )}
            <FormControlLabel
                label="I'm unable to donate but will contribute in other awesome ways"
                control={<Checkbox
                    checked={noDonate}
                    onChange={() => setNoDonate(!noDonate)}
                    />
            } />
            <LoadingButton
                sx={{ m: 2 }}
                disabled={!canSubmit()}
                variant="contained"
                loading={submitLoading}
                onClick={onSubmit}
                loadingPosition="start"
                >RSVP</LoadingButton>

        </Box>
    </Box>
}

export default RSVPRapper
