import React from 'react'
import { useAppMeta } from '../meta/meta.actions'
import { useUser } from '../user/user.actions'
import CircularProgress from '@mui/material/CircularProgress';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom'
import BurnEvent from '../event/event'
import MainContainer from './mainContainer'
import AdminView from '../admin/adminView'
import { ThemeProvider } from '@mui/material'
import { themeOptions } from '../theme';
import RSVP from '../event/rsvp';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../auth/auth.actions";

interface RouterProps {
    userEmail: string
}

const AppRouter: React.FC<RouterProps> = (props) => {
    const [appMeta, appMetaLoading, appMetaError] = useAppMeta()
    const [user, userLoading, userError] = useUser(props.userEmail)

    const meta = appMeta?.data();
    const u = user?.data();

    if (appMetaLoading || userLoading) {
        return <CircularProgress />
    }

    // if the user is not enabled or doesn't exist
    if (!u?.isEnabled) {
        return <>
            <div className='unauthorised'>Issue logging in. Please check your using the correct email address or get in touch with us for help</div>
            <Button
                color="secondary"
                endIcon={<LogoutIcon />}
                onClick={logout}
            >
                Logout
            </Button>
        </>
    }

    const childRoutes: RouteObject[] = []

    if (u.isAdmin) {
        childRoutes.push({
            path: "admin",
            element: <AdminView appMeta={meta} />,
            index: true
        })
    }

    childRoutes.push(...[
        {
            path: "events",
            element: <BurnEvent meta={meta} />,
            index: true
        },
        {
            path: "/rsvp/*",
            element: <RSVP appMeta={meta} userEmail={u.email} />,
            index: true
        },
        {
            path: "*", // fallback
            element: <BurnEvent meta={meta} />,
            index: true
        }
    ])

    const router = createBrowserRouter([{
        element: <MainContainer user={u} backgroundURL={meta.backgroundURL} />,
        children: childRoutes
    }])

    return <ThemeProvider theme={themeOptions}>
                <RouterProvider router={router} />
    </ThemeProvider>
}

export default AppRouter
