import React, { useEffect, useState } from "react"
import { useEvent } from "./event.actions"
import { EventDetails } from "./event.types"
import MDEditor from '@uiw/react-md-editor';
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { themeOptions } from "../theme";
import { AppMeta } from "../meta/meta.types";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';

interface BurnEventProps {
    meta: AppMeta
    userEmail?: string
}

const BurnEvent: React.FC<BurnEventProps> = ({ meta, userEmail }) => {
    const [event, eventLoading, eventLoadingError] = useEvent(meta.currentEvent)
    const [eventDetails, setEventDetails] = useState<EventDetails | undefined>()
    const navigate = useNavigate()

    useEffect(() => {
        if (!eventLoading && event) {
            const data = event.data()
            if (data) {
                setEventDetails(data);
            }
        }
    }, [event, eventLoading])

    if (eventLoading || !eventDetails) {
        return <CircularProgress />
    }

    return <Container fixed> <Box sx={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        backgroundColor: "#0d1117",
        "& > img(style)": { textAlign: "center" },
        my: "64px",
        borderRadius: "4px"
        }}
        className="burn-event"
        >
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                placeContent: "center end"
            }} >
            <Typography
                align="center"
                sx={{ flex: "1 1 0%", color: themeOptions.palette.text.primary }}
                variant="h3" className="title">
                    {eventDetails?.title}
            </Typography>
            {eventDetails?.showTickets && (
                    <Button
                        sx={{ alignSelf: "center" }}
                        variant="contained"
                        onClick={() => navigate("/rsvp")}
                    >
                        Reserve your place
                    </Button>
                )}
        </Box>
        <MDEditor.Markdown
            style={{ padding: 16 }}
            source={eventDetails.details}
        />
        {eventDetails?.showTickets && (
                <Button
                    sx={{ alignSelf: "center" }}
                    variant="contained"
                    onClick={() => navigate("/rsvp")}
                >
                    Reserve your place
                </Button>
            )}
    </Box></Container>
}

export default BurnEvent;
