import React, { useState } from "react"
import { useEvents } from "../event/event.actions"
import { useAppMeta } from "../meta/meta.actions"
import { AppMeta } from "../meta/meta.types"
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import EventEditorNav from "./eventEditor";
import UserAdmin from "./users";
import RsvpTable from "./rsvpTable";

interface AdminViewProps {
    appMeta: AppMeta
}

enum tabSelection {
    events,
    users,
    rsvps
}

const AdminView: React.FC<AdminViewProps> = (props) => {
    const [tab, setTab] = useState(tabSelection.events)

    return <Box sx={{
        backgroundColor: "secondary.dark",
        display: "flex",
        flexDirection: "column",
        width: 1.0,
        alignItems: "center",
        flexGrow: 1
    }}>
        <Tabs
            value={tab}
            onChange={(_, i) => setTab(i)}
            sx={{ marginBottom: "12px", mr: "auto" }}
        >
            <Tab label="Events" />
            <Tab label="Users" />
            <Tab label="Rsvps" />
        </Tabs>
        {tab === tabSelection.events && (
            <EventEditorNav appMeta={props.appMeta} />
        )}
        {tab === tabSelection.users && (
            <UserAdmin />
        )}
        {tab === tabSelection.rsvps && (
            <RsvpTable appMeta={props.appMeta} />
        )}
    </Box>
}

export default AdminView
