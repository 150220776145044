import { collection, doc, DocumentData, FirestoreDataConverter, WithFieldValue } from 'firebase/firestore'
import { useCollection, useCollectionData, useDocument } from 'react-firebase-hooks/firestore'
import { firestoreDB } from '../firebase'
import { User } from './user.types'

const postConvertor: FirestoreDataConverter<User> = {
  toFirestore (post: WithFieldValue<User>): DocumentData {
    return { isAdmin: post.isAdmin, isEnabled: post.isEnabled }
  },
  fromFirestore (snapshot, options?): User {
    const data = snapshot.data(options)
    return {
      isAdmin: data.isAdmin,
      isEnabled: data.isEnabled,
      email: snapshot.id
    }
  }
}

export const useUser = (email: string) => {
  return useDocument(doc(firestoreDB, 'users', email).withConverter(postConvertor), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })
}

export const useUsers = () => {
    return useCollectionData(collection(firestoreDB, "users").withConverter(postConvertor), {
        snapshotListenOptions: { includeMetadataChanges: true }
    })
}
