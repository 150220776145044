import { doc, DocumentData, FirestoreDataConverter, WithFieldValue } from 'firebase/firestore'
import { useDocument } from 'react-firebase-hooks/firestore'
import { firestoreDB } from '../firebase'
import { AppMeta } from './meta.types'

const postConvertor: FirestoreDataConverter<AppMeta> = {
  toFirestore (post: WithFieldValue<AppMeta>): DocumentData {
    return { currentEvent: post.currentEvent }
  },
  fromFirestore (snapshot, options?): AppMeta {
    const data = snapshot.data(options)
    return {
      currentEvent: data.currentEvent,
      backgroundURL: data.backgroundURL
    }
  }
}

export const useAppMeta = () => {
  return useDocument(doc(firestoreDB, 'meta', 'default').withConverter(postConvertor), {
    snapshotListenOptions: { includeMetadataChanges: true }
  })
}
