// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  getAuth
} from 'firebase/auth'
import {
  getFirestore
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { PaymentIntent } from "@stripe/stripe-js"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyARARNKdqf03910IQ8Lom2qGC7ps8RMt9Q',
  authDomain: 'burning-brumby.firebaseapp.com',
  databaseURL: 'https://burning-brumby-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'burning-brumby',
  storageBucket: 'burning-brumby.appspot.com',
  messagingSenderId: '84126437244',
  appId: '1:84126437244:web:047470ab0ead58aa9d5ef9'
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
export const firestoreDB = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp, 'australia-southeast1')
// connectFunctionsEmulator(functions, "localhost", 5001)

export const createPayment = httpsCallable<{ amount: number }, { payment: PaymentIntent }>(functions, "newPayment")
export const updatePayment = httpsCallable<{ paymentID: string, amount: number }>(functions, "updatePayment")
export const sendRSVPEmail = httpsCallable<{ name: string, emailAddress: string }>(functions, "sendConfirmEmail")
