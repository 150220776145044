import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth"
import { firebaseAuth } from "../firebase"
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import firebase from 'firebase/compat/app';
import { signInWithEmailAndPassword } from "firebase/auth";
import AppRouter from "../router/router";

const AuthWrapper = () => {
    const [user, loading, error] = useAuthState(firebaseAuth)

    return (
        <>
            {!loading && !user && (
                <StyledFirebaseAuth
                    firebaseAuth={firebaseAuth}
                    uiConfig={{
                        signInOptions: [{
                            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            requireDisplayName: false
                        }
                        ],
                        siteName: "Burning Brumby"
                    }}
                />
            )}
            {
                !loading && user && user.email && (
                    <AppRouter userEmail={user.email} />
                )
            }
        </>
    )
}

export default AuthWrapper;
