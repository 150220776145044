import { firebaseAuth, firestoreDB } from '../firebase'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth'
import {
  collection,
  addDoc
} from 'firebase/firestore'

export const logInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(firebaseAuth, email, password)
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
  try {
    const res = await createUserWithEmailAndPassword(firebaseAuth, email, password)
    const user = res.user
    await addDoc(collection(firestoreDB, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email
    })
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(firebaseAuth, email)
    alert('Password reset link sent!')
  } catch (err: any) {
    console.error(err)
    alert(err.message)
  }
}

export const logout = () => {
  signOut(firebaseAuth)
}
