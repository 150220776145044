import { collection, doc, DocumentData, DocumentReference, FirestoreDataConverter, updateDoc, WithFieldValue } from 'firebase/firestore'
import { useCollection, useCollectionData, useDocument, useDocumentData } from 'react-firebase-hooks/firestore'
import { firestoreDB } from '../firebase'
import { EventDetails, EventRSVP } from './event.types'

const postConvertor: FirestoreDataConverter<EventDetails> = {
    toFirestore (post: WithFieldValue<EventDetails>): DocumentData {
        return {
            name: post.name ?? "",
            title: post.title ?? "",
            ticketsUrl: post.ticketsUrl ?? "",
            showTickets: post.showTickets ?? false,
            details: post.details ?? "",
            lastUpdated: post.lastUpdated ?? {
                by: "",
                when: ""
            },
            isVisible: post.isVisible ?? false
        }
    },
    fromFirestore (snapshot, options?) {
        const data = snapshot.data(options)
        return {
            details: data.details,
            lastUpdated: data.lastUpdated ?? {
                by: "",
                when: ""
            },
            name: data.name ?? "",
            showTickets: data.showTickets ?? false,
            ticketsUrl: data.ticketsUrl ?? "",
            title: data.title ?? "",
            isVisible: data.isVisible ?? false
        }
    }
}

// get a list of all events
export const useEvents = () => {
    return useCollection(collection(firestoreDB, 'events').withConverter(postConvertor), {
        snapshotListenOptions: { includeMetadataChanges: true }
    })
}

export const useEvent = (event: string) => {
    return useDocument<EventDetails>(doc(firestoreDB, "events", event).withConverter(postConvertor), {
        snapshotListenOptions: { includeMetadataChanges: true }
    })
}

const rsvpConvertor: FirestoreDataConverter<EventRSVP> = {
    fromFirestore (snapshot, options?) {
        const data = snapshot.data(options)
        return {
            userEmail: data.userEmail ?? "",
            name: data.name ?? "",
            selfChecked: data.selfChecked ?? false,
            parkingChecked: data.parkingChecked ?? false,
            websiteChecked: data.websiteChecked ?? false,
            ownIdeas: data.ownIdeas ?? "",
            kitchen: data.kitchen ?? "",
            land: data.land ?? "",
            workshop: data.workshop ?? "",
            construction: data.construction ?? false,
            sober: data.sober ?? false,
            ownIdea: data.ownIdea ?? false,
            yours: data.yours ?? false,
            noDonate: data.noDonate ?? false,
            rsvpConfirmed: data.rsvpConfirmed ?? false,
            paymentClientSecret: data.paymentClientSecret,
            paymentID: data.paymentID,
            paymentConfirmed: data.paymentConfirmed ?? false,
            donationAmount: data.donationAmount,
            confirmEmailSent: data.confirmEmailSent
        }
    },
    toFirestore (post: WithFieldValue<EventRSVP>): DocumentData {
        return {
            ...post
        }
    }
}

export const useRSVP = (event: string, userEmail: string) => {
    return useDocumentData<EventRSVP>(doc(firestoreDB, "events", event, "rsvps", userEmail).withConverter(rsvpConvertor),
    { snapshotListenOptions: { includeMetadataChanges: true } })
}

export const useRSVPs = (event: string) => {
    return useCollectionData<EventRSVP>(collection(firestoreDB, "events", event, "rsvps").withConverter(rsvpConvertor),
    { snapshotListenOptions: { includeMetadataChanges: true } })
}
