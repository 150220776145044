import React, { useState, useEffect } from "react"
import { AppMeta } from "../meta/meta.types"
import Box from '@mui/material/Box';
import { useEvent, useEvents } from "../event/event.actions";
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { updateDoc, setDoc, doc } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MDEditor from '@uiw/react-md-editor';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { modalStyle } from "../theme";
import { firestoreDB } from "../firebase"

interface EventEditoProps {
    appMeta: AppMeta
    selectedEvent: string
}

const EventEditor: React.FC<EventEditoProps> = (props) => {
    const [e, eventLoading, eventsError] = useEvent(props.selectedEvent)

    // event state
    const [isActive, setIsActive] = useState(false)
    useEffect(() => {
        const eventName = e?.id
        if (eventName) {
            setIsActive(eventName === props.appMeta.currentEvent)
        }
    }, [e, props.appMeta])

    const [event, setEvent] = useState(e?.data())
    useEffect(() => {
        const data = e?.data()
        if (data) {
            console.log("updating")
            setEvent(data)
        }
    }, [e])

    const onSave = () => {
        if (e && event) {
            updateDoc(e.ref, { ...event })
            .catch(e => console.log(e))
        }
    }

    return <Box sx={{
            display: "flex",
            flexDirection: "column"
        }}>
        <IconButton
            sx={{ alignSelf: "flex-start", marignLeft: "32px" }}
            onClick={onSave}
        ><SaveIcon /></IconButton>
        {event && (
            <Box
                sx={{
                    color: "white",
                    backgroundColor: "grey",
                    width: "75%",
                    alignSelf: "center",
                    marginTop: "16px",
                    borderRadius: "4px",
                    padding: "16px"
                  }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Details
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <FormControlLabel label="Active event" control={
                                <Switch checked={isActive} onChange={(_, active) => setIsActive(active)} />
                            }/>
                            <FormControlLabel label="Show event" control={
                                <Switch checked={event.isVisible} onChange={(_, checked) => setEvent({ ...event, isVisible: checked })} />
                            }/>
                            <FormControlLabel label="Show tickets" control={
                                <Switch checked={event.showTickets} onChange={(_, checked) => setEvent({ ...event, showTickets: checked })} />
                            }/>
                        </FormGroup>
                        <Box component="form" sx={{ '& > :not(style)': { m: 1 } }}>
                        <TextField
                            required
                            fullWidth
                            label="Name"
                            value={event.name}
                            onChange={(e) => setEvent({ ...event, name: e.target.value })}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Title"
                            value={event.title}
                            onChange={(e) => setEvent({ ...event, title: e.target.value })}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Tickets URL"
                            value={event.ticketsUrl}
                            onChange={(e) => setEvent({ ...event, ticketsUrl: e.target.value })}
                        />
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        Content
                    </AccordionSummary>
                    <AccordionDetails>
                        <MDEditor
                            value={event.details}
                            onChange={text => setEvent({ ...event, details: (text ?? event.details) })}
                            height={1000}
                        />
                    </AccordionDetails>
                </Accordion>
            </Box>
        )}
    </Box>
}

interface EventEditorNavProps {
    appMeta: AppMeta
}

const EventEditorNav: React.FC<EventEditorNavProps> = (props) => {
    const [e, eventsLoading, eventsError] = useEvents()
    const [selectedEvent, setSelectedEvent] = useState(props.appMeta.currentEvent)

    const [eventNames, setEventsNames] = useState([] as string[])
    const [tab, _setTab] = useState(0)
    const setTab = (tabIndex: number) => {
        _setTab(tabIndex)
        setSelectedEvent(eventNames[tabIndex])
    }

    useEffect(() => {
        if (!eventsLoading && !eventsError && e) {
            setEventsNames(e.docs.map(d => d.id))
        }
    }, [eventsLoading, eventsError, e])

    const [newModal, setNewModal] = useState(false)
    const [newEventName, setNewEventName] = useState("")
    const [newEventLoading, setNewEventLoading] = useState(false)
    const onNewEvent = () => {
        setNewEventLoading(true)
        setDoc(doc(firestoreDB, "events", newEventName), {
            name: newEventName
        })
        .catch((e) => console.log(e))
        .finally(() => {
            setNewEventLoading(false)
            setNewModal(false)
            setNewEventName("")
        })
    }

    return <Box sx={{
            backgroundColor: "primary",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1
        }}>
        {eventsLoading
            ? <CircularProgress />
            : (
            <>
                <Tabs
                    sx={{ backgroundColor: "primary" }}
                    value={tab}
                    onChange={(_, i) => setTab(i)}
                    >
                    {eventNames.map(name =>
                        <Tab
                            key={name}
                            label={name}
                        />
                    )}
                {/* <Button onClick={() => setNewModal(true)} sx={{ ml: "16px" }} variant="contained">New event</Button> */}
                </Tabs>
                <EventEditor selectedEvent={selectedEvent} appMeta={props.appMeta} />
                <Modal sx={modalStyle} open={newModal} onClose={() => setNewModal(false)}>
                    <Box>
                        <Typography variant="h6">Enter event name</Typography>
                        <TextField
                            required
                            fullWidth
                            label="Event name"
                            value={newEventName}
                            onChange={(e) => setNewEventName(e.target.value)}
                        />
                        <LoadingButton
                            loading={newEventLoading}
                            loadingPosition="start"
                            variant="contained"
                            onClick={onNewEvent}
                            >
                                Create
                        </LoadingButton>
                    </Box>
                </Modal>
            </>
        )}
    </Box>
}

export default EventEditorNav
