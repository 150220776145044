import React from 'react'
import logo from './logo.svg'
import './App.css'
import AuthWrapper from './auth/AuthWrapper'

function App () {
  return (
    <AuthWrapper />
  )
}

export default App
