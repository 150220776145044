import { createTheme } from '@mui/material'

export const themeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#720f81'
    },
    secondary: {
      main: '#e040fb'
    }
  },
  typography: {
    allVariants: {
        color: 'white',
        marginTop: 1,
        marginBottom: 1
    }
  },
  spacing: 16
})

export const boxTheme = createTheme({
  ...themeOptions,
  spacing: [0, 16, 16 * 16, 16 * 64]
})

export const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    color: themeOptions.palette.text.primary
  };
