import React from "react"
import { useRSVPs } from "../event/event.actions"
import { AppMeta } from "../meta/meta.types"
import { Box, Typography } from "@mui/material"
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridColDef, GridToolbar, GridRenderCellParams, GridValidRowModel, GridValueGetterParams, GridValueFormatterParams } from '@mui/x-data-grid';

interface RsvpTableProps {
    appMeta: AppMeta
}

const RsvpTable: React.FC<RsvpTableProps> = ({ appMeta }) => {
    const [rsvps, rsvpsLoading, rsvpsError, collectionRef] = useRSVPs(appMeta.currentEvent)

    interface rowModel extends GridValidRowModel {
        name: string
        email: string
        noDonate: boolean
        donationAmount: number
        confirmed: boolean
        ownIdeas: string
        kitchen: boolean
        land: boolean
        workshop: boolean
        construction: boolean
        sober: boolean
        ownIdea: boolean
        yours: boolean
    }

    const checkRender = (params: GridRenderCellParams<boolean, rowModel>) => (
        <Checkbox checked={params.value} />
    )

    const columns: GridColDef[] = [
        { field: "name", headerName: "Name", sortable: true, width: 150 },
        { field: "email", headerName: "Email", sortable: true, width: 150 },
        {
            field: "confirmed",
            headerName: "Confirmed",
            sortable: true,
            type: "boolean",
            renderCell: checkRender
        },
        {
            field: "donation",
            headerName: "Donation",
            type: "number",
            valueGetter: (params: GridValueGetterParams<any, rowModel>) =>
                params.row.noDonate ? 0 : params.row.donationAmount,
            valueFormatter: (params: GridValueFormatterParams) => `$${params.value}`
        },
        { field: "kitchen", headerName: "Kitchen", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "land", headerName: "Landcare", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "workshop", headerName: "Workshop", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "construction", headerName: "Construction", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "sober", headerName: "Sober people", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "ownIdea", headerName: "Has own idea", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "yours", headerName: "Yours to place", type: "boolean", renderCell: checkRender, sortable: true },
        { field: "ownIdeas", headerName: "Own ideas", sortable: false }
    ]

    const rows: rowModel[] = (rsvps ?? []).map(r => ({
        id: r.userEmail,
        name: r.name,
        email: r.userEmail,
        confirmed: r.rsvpConfirmed,
        donationAmount: r.donationAmount,
        noDonate: r.noDonate,
        kitchen: r.kitchen,
        construction: r.construction,
        land: r.land,
        ownIdea: r.ownIdea,
        sober: r.sober,
        workshop: r.workshop,
        yours: r.yours,
        ownIdeas: r.ownIdeas
    }))

    const totalAttendies = rsvps?.filter(r => r.rsvpConfirmed).length ?? 0
    const totalDonations = rsvps?.reduce((total, r) => total + (r.noDonate || !r.rsvpConfirmed ? 0 : r.donationAmount ?? 0), 0)

    return <Box sx={{
            flexGrow: 1,
            height: "600px",
            width: 0.75,
            display: "flex",
            flexDirection: "column",
            my: 2,
            p: 1,
            backgroundColor: "primary.dark"
        }}>
        <Typography variant="h5" align="center">{`RSVPs for ${appMeta.currentEvent}`}</Typography>
        <Typography variant="body1">{`${totalAttendies} total confirmed attendies`}</Typography>
        <Typography variant="body1">{`$${totalDonations} donated`}</Typography>
        <DataGrid
            sx={{ backgroundColor: "black", my: 2 }}
            rows={rows}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
        />
    </Box>
}

export default RsvpTable
